<template>
  <el-button
      :autofocus="attributes.autofocus"
      :circle="attributes.circle"
      :class="attributes.class"
      :disabled="attributes.disabled"
      :icon="attributes.icon"
      :loading="attributes.loading"
      :native-type="attributes.nativeType"
      :plain="attributes.plain"
      :round="attributes.round"
      :size="attributes.size"
      :type="attributes.type"
  >
    {{ content }}
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    loading: {
      default: false,
    },
    attributes: {
      type: Object,
      default: () => ({
        size: '',
        type: '',
        plain: false,
        round: false,
        loading: false,
        disabled: false,
        icon: '',
        autofocus: false,
        nativeType: 'button',
      }),
    },
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
