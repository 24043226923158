import EventBus from '../../../../eventBus';
// import notification from '../../../../notification/notify'

export default {
  created() {
    EventBus.$on('POPOVER_OPENED', (componentName) => {
      if (componentName !== this.field.name) {
        this.show = false;
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('POPOVER_OPENED');
  },
};
