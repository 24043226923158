import EventBus from '../../../../eventBus';
import notification from '../../../../notification/notify';
import buttonFunctions from './buttonFunctions';

export default {
  mixins: [buttonFunctions],
  props: [],
  components: {
    // Tooltip
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    EventBus.$on('POPOVER_OPENED', (componentName) => {
      if (componentName !== this.field.name) {
        this.show = false;
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('POPOVER_OPENED');
  },
  computed: {
    saveURL() {
      return this.meta.saveURL.replace('{id}', this.data.id);
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        EventBus.$emit('POPOVER_OPENED', this.field.name);
      }
    },
    onSaved(response) {
      this.toggle();
      notification.notify(
        this.$t('notify.success'),
        response.data.message,
        'success');
    },
  },
};
