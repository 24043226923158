<template>
  <div class="d-inline-block">
    <custom-button
        :attributes="meta.button"
        :content="field.label && field.label.name ? $t(field.label.name) : ''"
        @click.native="toggle"
    />
    <transition name="el-fade-in">
      <time-log-form
          :activities="dictionaries[field.dictionary]"
          :id="data.id"
          :saveURL="meta.saveURL ? meta.saveURL : ''"
          @cancel="toggle"
          @saved="saved"
          class="popover--container"
          v-if="show"
      />
    </transition>
  </div>
</template>

<script>
import abstractForm from '../../mixin/index';
import TimeLogForm from './timeLogForm.vue';
import button from '../../mixin/button';
import CustomButton from '../../UI/Button.vue';
import EventBus from '../../../../../eventBus';

export default {
  name: 'index',
  mixins: [abstractForm, button],
  components: {TimeLogForm, CustomButton},
  methods: {
    saved(data) {
      EventBus.$emit('FETCH_TIME_ENTRIES');
      EventBus.$emit('FETCH_ACTIVITIES');
      this.onSaved(data);
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.popover--container {
  padding: 10px;
  position: absolute;
  top: 70px;
  background-color: rgb(254, 255, 255);
  width: 80%;
  z-index: 9999999;
  border-radius: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, .1);
}
</style>
